@use "sass:math";
@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.content {
  display: flex;

  @include md {
    display: block;
  }
}

.panel {
  width: 100%;
}

.imageContainer {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $color-gray-light;
  margin: 0 $space-xxs $space-xs 0;
  border-radius: $space-xxxxs;
}

.imageWrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  padding-bottom: 100%;
}

.imageLink {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.productName,
.price {
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
}

.colors {
  margin: $space-xxs 0;
}

.sizeSelector {
  margin: $space-xs 0 $space-xxxs;
}
