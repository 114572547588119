@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.wrapper {
  max-width: $content-wrapper-medium-width;
  margin: $space-base auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  gap: $space-base;
  padding: 5px;

  @include md-min {
    flex-flow: row nowrap;
  }
}

.checkoutLoginForm {
  border: 1px solid $color-border-light;
  width: 100%;

  @include md-min {
    width: 60%;
  }
}

.title {
  background-color: $color-white-gray;
  font-size: 15px;
  color: $color-black;
  font-weight: $font-weight-bold;
  padding: $space-sm $space-xs;
  width: 100%;
}

.body {
  padding: $space-xs $space-xs $space-md;
}

.guestOptions {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  gap: $space-base;

  @include md-min {
    width: 40%;
  }
}

.card {
  border: 1px solid $color-border-light;
}
