@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

$color-tab-primary: $color-primary-600 !default;

.tabs {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}

:is(body) .tab {
  flex: 1;
  min-width: 100px;
  text-align: center;
  line-height: 34px;
  height: 34px;
  border-bottom: 2px solid $color-gray;
  font-weight: $font-weight-bold;

  &:hover {
    cursor: pointer;
  }
}

.tabsBody {
  padding: 20px 10px;
}

:is(body) .activeTab {
  border-bottom: 4px solid $color-tab-primary;
}
