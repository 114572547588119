@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

$space-medium: 15px;

.form {
  width: 100%;
}

.passwordDetails {
  color: $color-black-gray;
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
  margin-top: -$space-medium;

  ul {
    padding: 0;
    list-style: none;

    li {
      line-height: 25px;

      &:first-child {
        list-style: none;
      }
    }
  }
}

.buttonsContainer {
  display: flex;
  gap: 10px;
}
