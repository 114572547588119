@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.container {
  width: 100%;
  padding: $space-md $space-xs $space-xs;
}

.title {
  font-family: $body-font;
  font-size: $font-size-base;
  text-decoration: underline;
}

.subtitle {
  font-size: $font-size-md;
  margin: 0;

  @include md-min {
    font-size: $font-size-lg;
  }
}

.couponsContainer {
  margin-top: 7px;
  margin-bottom: 26px;
}
