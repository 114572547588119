@use "sass:math";
@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.container {
  --navigation-button-size: 40px;
  margin-block: $space-md;
  border-top: 0.5px solid $color-border;
  width: 100%;
}

.title {
  font-family: $heading-font;
  font-size: $font-size-sm;
  font-weight: $font-weight-light;
  text-align: center;
  letter-spacing: $letter-spacing-headline;
  padding-top: $space-md;
  padding-bottom: $space-sm;
}

:is(body) {
  .slider {
    width: 100%;
  }

  .wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-items: center;
  }

  .navigation {
    @include square;
    margin: unset;
    padding: unset;
    border-radius: 50%;
    border: 1px solid $carousel-navigation;
    color: $carousel-navigation;
    transition: border 1s, color 1s;
    background: $carousel-navigation-background;
    width: var(--navigation-button-size);
    margin-inline: var(--navigation-padding);

    @media (hover: hover) {
      &:hover {
        border: 1px solid $color-black;
        color: $color-black;
      }
    }

    svg {
      transform: scale(0.7);
      width: var(--navigation-button-size);
    }
  }
}