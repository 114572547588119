@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.small, .medium, .large {
    background-color: $color-primary-500;
    color: white;
    min-height: 350px;
    place-content: center;
    text-align: center;
}

.small {
    grid-area: span 1 / span 1;

    @include sm-max {
        grid-area: span 1 / span 2;
    }
}

.medium {
    grid-area: span 1 / span 2;
}

.large {
    grid-area: span 1 / span 4;

    @include sm-max {
        grid-area: span 2 / span 2;
    }
}