@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.container {
  position: absolute;
  top: 0;
  margin: $space-xs;

  @include md-min {
    position: relative;
    max-width: 520px;
    width: 100%;
  }
}

.header {
  height: $gdpr-modal-header-height;
  background-color: $color-white-gray;
  display: flex;
  align-items: center;
}

.headerContainer {
  width: 100%;
}

.body {
  padding: $space-base $space-md $space-md;

  @include md-min {
    padding: $space-base 40px 40px;
  }
}

.title {
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: $font-size-xl;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  gap: $space-xxs;
}
