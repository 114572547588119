@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.wrapper {
  width: 100%;
  margin-bottom: $space-xs;
  border: 1px solid $color-gray-2;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $color-black;
  padding: $space-sm $space-xs;

  h1 {
    text-transform: uppercase;
    font-family: $body-font;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing-headline;
    color: $color-white;
    margin: 0;
  }
}
