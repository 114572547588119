@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

// This upper is a zero-height container that only
// helps position the actual content.
:is(body) .headsUpNotification {
  width: 100%;
  height: 0;

  // Since height = 0, all vertical overflow should
  // be visible.
  overflow-y: visible;
  position: relative;

  // The header has a z-index of 1000.

  .content {
    flex-direction: column;
    background-color: $color-white;
    padding: $space-xs;
    z-index: $notification-content-layer;

    width: 100vw;
    min-height: 60px;

    box-shadow: 0 10px 10px -10px rgba($color-black-gray, 0.4);

    // Default state (hidden)
    opacity: 0;
    transform: translateY(calc(-100%));
    transition: 250ms;
    pointer-events: none;
    justify-content: space-between;
    align-items: center;
    visibility: hidden;

    &.visible {
      opacity: 1;
      transform: translateY(0);
      pointer-events: all;
      visibility: visible;
    }

    .close {
      width: 12px;
      height: 12px;
      position: absolute;
      right: $space-xs;

      margin-inline: $space-xxxs;
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: flex-end;

      .closeIcon {
        // X size = 12px
        font-size: 19.2px;
      }
    }
  }

  .error {
    color: $color-error-dark;
    border: $color-error-dark 1px solid;
  }
}

$notification-width-desktop: 350px;
$notification-margin-desktop: 15px;

@include desktop {
  :is(body) .headsUpNotification {
    .content {
      display: none;
      width: $notification-width-desktop;
      position: absolute;
      top: $notification-margin-desktop;
      right: $notification-margin-desktop;
      left: auto;

      border-radius: 5px;

      // Default state (hidden)
      transform: translateX(
        $notification-width-desktop + $notification-margin-desktop
      );

      &.visible {
        transform: translateX(0);
        display: flex;
      }
    }
  }
}
