@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.container {
  width: 100vw;

  @include lg-min {
    width: 360px;
    height: auto;
  }
}

.header {
  background-color: $bg-grey;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  font-size: $font-size-xs;
  letter-spacing: 1px;

  button {
    font-size: $font-size-base;
  }
}

.countdownContainer {
  padding: 10px;
  font-size: $font-size-sm;
  line-height: $line-height-relaxed;
}

.countdown {
  margin-bottom: $space-md;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 10px;
}

.button {
  margin: 10px 0;

  &:last-child {
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    margin-top: $space-xxs;
  }
}
