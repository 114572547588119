@use "sass:math";
@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.productGrid {
    display: grid;
    grid-auto-flow: row dense;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(350px, auto); /* Minimum height of 350px */
    gap: 0.5rem;

    padding: 20px 5rem;
    justify-content: center;
    max-width: $wrapper-max-width;
    margin: auto;

    @include sm-max {
        padding-inline: 0;
        grid-template-columns: repeat(2, 1fr);
    }
}