@use "@/styles/variables" as *;

@forward "./styles.base.module.scss";

.wrapper {
  margin-top: $space-xxxxs;
  margin-right: $space-xxs;
}

.dropdown {
  box-shadow: rgb(128 128 128 / 40%) 0 2px 10px 0;
}

:is(body) .buttonWithIcon {
  padding: $space-xxxs;
  font-size: $font-size-md;
}

.spinner {
  margin: 20px 50px;
}
