////
/// Defines color variables shared between components without crossing component boundaries.
/// --
/// A handful of UI elements and components are stylistically dependent on one another. In these cases,
/// component-specific variables must be shared with other components, but without violating the component
/// boundary and introducing a dependency cycle. Moreover, it's also sometimes useful to export variables
/// to TypeScript, to use as a prop to another customizable component. We call these types of variables
/// "contextual colors", and they should be defined (and exported if needed) from this file.
///
/// @see Tier 1 in `docs/guides/brand-styling.md` for the theming guide.
/// @group variables/contextualColors
/// --
/// SUGGESTED NAMING CONVENTION:
/// $color-<owner_component>-<owner_attribute>: <value> !default;
/// EX:
/// $color-order-summary-title-foreground: $color-primary !default;
////

@use "./baseColors" as *;

// Uncategorized
$color-gray-divider: $color-light-gray-2 !default;
$color-gray-product-item-divider: $color-gray-divider !default;
$color-border: $color-light-gray !default;
$color-border-light: $color-light-gray-3 !default;
$color-disabled: rgba($color-black, 0.3) !default;
$color-not-eligible: $color-danger-darker !default;
$color-spinner-icon: $color-primary !default;

// Fonts
$color-font-base: $color-black !default;

// Variation Panel
$out-of-stock-background: $color-white-gray !default;
$color-price-disabled: $color-dark-gray !default;
$carousel-navigation: $color-gray !default;
$carousel-navigation-background: $color-white-gray !default;
$carousel-navigation-active: $color-dark-gray !default;

// Navigation
$color-navigation-border: $color-lighter-gray !default;
$color-navigation-background: $color-white !default;
$color-navigation-dim-background: $color-white-gray !default;
$color-navigation-foreground: $color-black !default;
$color-navigation-mobile-background: $color-white !default;

// Headers
$color-header-foreground: $color-white-gray !default;
$color-header-foreground-hover: $color-lighter-gray !default;
$color-header-background: $color-primary !default;
$color-header-border: $color-primary !default;
$color-header-logo: $color-white-gray !default;
$color-header-dropdown-background: $color-white !default;
$color-header-dropdown-foreground: $color-black-gray !default;
$color-header-cart-badge-foreground: $color-white !default;
$color-header-cart-badge-background: $color-header-foreground !default;
$color-header-checkout-background: $color-primary-500 !default;
$color-header-checkout-text: $color-black !default;
$checkout-header-border: 0 !default;

// Footer
$color-footer-background: $color-navigation-background !default;
$color-footer-foreground: $color-navigation-foreground !default;

// Returns and Account
$color-returns-border: $color-light-gray-3 !default;
$color-returns-header: $color-light-gray-6 !default;

// Other
$bg-grey: $color-lighter-gray !default;
$color-text: $color-black !default;
$color-text-muted: $color-dark-gray !default;
$color-cart-semi-muted: $color-gray !default;

// Content
$semi-transparent-border: rgba($color-black, 0.15) !default;
$color-content-border: $color-gray-product-item-divider !default;

// Checkout
$color-checkout-header: $color-light-gray-6 !default;
$color-checkout-border: $color-light-gray-3 !default;

// Exchanges
$color-exchange-ui-bg: $color-danger-dark !default;

// EXCEPTION CASES
// These colors cannot vary between sites.
$color-paypal-button: #ffc439;

// Blog colors
$color-category-design: $color-laurel-green !default;
$color-category-adventure: $color-vegas-gold !default;
$color-category-lifestyle: $color-cool-gray !default;
$color-category-expression: $color-deep-peach !default;
$color-category-guides: $color-moonstone-blue !default;
$color-category-activism: $color-coral !default;

// Shipping Methods
$color-holiday-messaging: $color-success-600 !default;
