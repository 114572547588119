@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.anotherReturnButton {
  @include md-min {
    margin-top: 10px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: $space-base;
}

.orderOne {
  order: 1;
}

.orderTwo {
  order: 2;
}

.disclaimer {
  display: flex;
  justify-content: center;
}
