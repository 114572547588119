@use "@/styles/variables" as *;

// Width and height for the stars.
$star-size: 15px !default;

// Side margins for each star.
$star-margin: 1.5px !default;

// Color of the stars.
$star-color: $color-primary !default;

// The container for the entire component.
.ratingStars {
  // All nice and centered
  display: flex;
  justify-content: center;
  align-items: center;

  // Star SVG
  > .star {
    height: $star-size;
    width: $star-size;
    margin: 0 $star-margin;
    color: $star-color;
  }

  .count {
    font-size: $font-size-xs;
    line-height: $line-height-none; // Centers the stars vertically.
    text-decoration: none;
    color: $color-black;
    letter-spacing: $letter-spacing-headline;
    padding-left: $space-xxxs;
  }
}
