@use "sass:math";
@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.container {
  margin: $space-md auto;
  padding: 0;
}

.title {
  margin-top: 0;
  font-size: $font-size-sm;
  line-height: $space-md;
}
